
import './App.css';

function App() {
  return (
   <>
   <h1 className="text-3xl font-bold underline">
      Hello world!
    </h1>
   </>
  );
}

export default App;
